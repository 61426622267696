import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { CopyBlock, dracula } from "react-code-blocks";

class AdvancedDependencyInjectionBlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Advanced Dependency Injection in Swift' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image dependency_injection_swift_bg_image" data-black-overlay="8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Advanced Dependency Injection in Swift</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />May 2, 2020</li>
                                        <li><FiUser />Mario Mouris</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>Dependency injection is one of those fancy terms that seem a little more complex than they really are. It’s quite a broad technique that can be implemented in many ways. In this article, we’ll discuss the different ways and how we can implement Dependency Containers to manage the dependency graph of our app.</p>
                                        <h2>Dependency Injection Definition</h2>
                                        <p>In software engineering, dependency injection is a technique in which an object receives other objects that it depends on. These other objects are called dependencies. In the typical "using" relationship the receiving object is called a client and the passed object is called a service.</p>
                                        <p>In this article, we're going to discuss Initializer Injection only, but you can find lots of other techniques to inject dependencies which will be covered in future articles.</p>

                                        <h3>Example</h3>
                                        <p>An easy example of initializer Injection injection in general would be a class named Client that depends on a dependency named Dependency with an implementation DependencyImplementation. Such an example can be implemented like this:</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`/// The client that needs the dependency to operate
class Client {
    /* Note the client knows nothing about the real implementation,
        it depends on the protocol instead of the implementation. */
    let dependency: Dependency
    
    init(dependency: Dependency) {
        self.dependency = dependency
    }
}

/// Defines what the dependency should do
protocol Dependency {
    func doSomethingCool()
}

/// Implements the dependency protocol
class DependencyImplementation: Dependency {
    func doSomethingCool() {
        // Does something cool...
    }
}

// Now, we create the client and 'Inject' the dependency in it through a constructor
let client = Client(dependency: DependencyImplementation())
`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Note how the client only depends on the protocol, rather than the actual implementation. </p>
                                        <h5>Pros of this approach:</h5>
                                        <ul>
                                            <li>Better testability.</li>
                                            <li>Higher modularity</li>
                                            <li>Reduces code coupling</li>
                                            <li>Gives the Injector more flexibility to choose which implementation to inject.</li>
                                        </ul>
                                        <h5>Cons:</h5>
                                        <ul>
                                            <li>This approach makes the Client class a Dependency itself, which can make things more complicated. (Which we’ll fix with a DependencyContainer)</li>
                                        </ul>

                                        <h2>Dependency Container</h2>
                                        <p>A Dependency container at its simplest form is a class that instantiates and configures objects. It’s the Injector class we mentioned earlier.</p>
                                        <p>To have a better understanding of it, let’s discuss a more complete example.</p>
                                        <h3>Example</h3>
                                        <p>Imagine you want to build a repository to handle all your authentication logic, let's call it UserSessionRepository . It handles the communication between your API and the local data store saving the tokens to the device storage (Preferably using Keychain of course 😉)</p>
                                        <p>Let's first create the protocol that's going to handle the API calls. We'll call it AuthRemoteAPI and its implementation MyAuthRemoteAPI.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`/// Handles authenticating the user
protocol AuthRemoteAPI {
    func createAccount(name: String, email: String, password: String) -> Promise<AuthTokens>
    func signIn(email: String, password: String) -> Promise<AuthTokens>
}

/// The actual implementation of AuthRemoteAPI
class MyAuthRemoteAPI: AuthRemoteAPI {
    func createAccount(name: String, email: String, password: String) -> Promise<AuthTokens> {
        // Calls API and returns with the result
    }
    
    func signIn(email: String, password: String) -> Promise<AuthTokens> {
        // Calls API and returns with the result
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Then, we'll create the protocol that's going to handle storing the tokens returned from AuthRemoteAPI to the local device storage.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`/// Handles storing tokens to device
protocol UserSessionDataStore {
    func getAuthTokens() -> Promise<AuthTokens>
    func save(authTokens: AuthTokens) -> Promise<AuthTokens>
    func deleteAuthTokens() -> Promise<()>
}

/// The actual implementation of UserSessionDataStore
class MyUserSessionDataStore: UserSessionDataStore {
    func getAuthTokens() -> Promise<AuthTokens> {
        /// Gets the auth tokens from the local storage
    }
    
    func save(authTokens: AuthTokens) -> Promise<AuthTokens> {
        /// Saves the auth tokens to the local storage
    }
    
    func deleteAuthTokens() -> Promise<()> {
        /// Deletes any stored tokens in the local storage
    }
`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Now, we'll create the UserSessionRepository protocol that will manage the communication between AuthRemoteAPI and UserSessionDataStore.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`/// Handles the user session through coordination between the local datastore and the authentication API
protocol UserSessionRepository {
    var remoteAPI: AuthRemoteAPI { get }
    var dataStore: UserSessionDataStore { get }
    
    func getTokens() -> Promise<AuthTokens>
    func createAccount(name: String, email: String, password: String) -> Promise<AuthTokens>
    func signIn(email: String, password: String) -> Promise<AuthTokens>
}

/// The actual implementation of UserSessionRepository
class MyUserSessionRepository {
    
    // MARK: - Properties
    let dataStore: UserSessionDataStore
    let remoteAPI: AuthRemoteAPI
    
    // MARK: - Initializer
    init(dataStore: UserSessionDataStore, remoteAPI: AuthRemoteAPI) {
        self.dataStore = dataStore
        self.remoteAPI = remoteAPI
    }
    
    func getTokens() -> Promise<AuthTokens> {
        /// Gets tokens from dataStore
    }
    
    func createAccount(name: String, email: String, password: String) -> Promise<AuthTokens> {
        /// Creates an account with remoteAPI, and then stores token with dataStore
    }
    
    func signIn(email: String, password: String) -> Promise<AuthTokens> {
        /// Signs user in with remoteAPI, and then stores token with dataStore
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>We now have a fully working model to deal with user authentication. But wait a second!! How are we going to initialize the UserSessionRepository and where?</p>
                                        <p>We'll assume that we already have a class AuthenticationViewModel that needs UserSessionRepository to function.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`class AuthenticationViewModel {
    let userSessionRepository: UserSessionRepository
    
    init(userSessionRepository: UserSessionRepository) {
        self.userSessionRepository = userSessionRepository
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>To inject the needed dependency into AuthenticationViewModel, we'll need a central place to know how objects should be initialized and configured. That's where DependencyContainer come into play.</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`/// Handles the dependency graph for the app
class DependencyContainer {
    func makeAuthenticationViewModel() -> AuthenticationViewModel {
        return AuthenticationViewModel(userSessionRepository: makeUserSessionRepository())
    }
    
    private func makeUserSessionRepository() -> UserSessionRepository {
        return MyUserSessionRepository(dataStore: makeUserSessionDataStore(),
                                       remoteAPI: makeAuthRemoteAPI())
    }
    
    private func makeUserSessionDataStore() -> UserSessionDataStore {
        return MyUserSessionDataStore()
    }
    
    private func makeAuthRemoteAPI() -> AuthRemoteAPI {
        return MyAuthRemoteAPI()
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>As you can see, this class now handles all the initialization and configuration of the objects in the apps. So, clients don’t need to know how to create their own dependencies anymore, and they don’t need to know about the implementation either. They can simply depend on the protocol.</p>

                                        <h2>Conclusion</h2>
                                        <p>Dependency injection is not that hard, but it’s a really powerful technique to be in your tool belt. It gives you a clear separation between object initialization and its usage which in turn reduces coupling between the different elements of the app.</p>
                                        <p>We’ve discussed initializer injection and dependency containers in this articles. In future articles, we’ll explore the other techniques of dependency injection and how to break the dependency container into child containers for better organization.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default AdvancedDependencyInjectionBlogDetails;
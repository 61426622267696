import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { CopyBlock, dracula } from "react-code-blocks";

class NetworkLayerAlamofirePromisekitBlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Write a network layer using Alamofire and PromiseKit' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image network_layer_alamofire_promisekit_bg_image" data-black-overlay="8">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Write a network layer using Alamofire and PromiseKit</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />May 24, 2020</li>
                                        <li><FiUser />Mario Mouris</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Blog Details */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p>Nowadays almost every app we use consumes APIs on way or another. It can be something that takes so much of your valuable development time , or it can be something really easy that you almost forget about it.</p>
                                        <p>I’ve discussed writing a network layer using RxSwift and Alamofire <a href="/blog/network-layer-alamofire-5-rxswit">here</a> before, but that was so outdated and I don’t agree with most of the things mentioned in that article anymore. That’s why I’m writing this new article to show you how I like to handle networking in my current projects. Hope you learn something.</p>
                                        <p>In this article, I’ll share the way I setup my network layer using Alamofire 5, PromiseKit 6.8, and Swift 5.</p>
                                        <h5>We’ll go through the following steps for this setup:</h5>
                                        <ol>
                                            <li>Creating Network Constants class to hold any constants related to our network layer.</li>
                                            <li>Building the API Router, which is responsible for building our API endpoints.</li>
                                            <li>Building the Alamofire+PromiseKit extension, that handles creating a calling the endpoint from API Router and converting it into a Promise.</li>
                                            <li>Making our models conform to the Codable protocol, so that they can be parsed into and from JSON.</li>
                                            <li>Actually making a request.</li>
                                        </ol>

                                        <p>Please note that in this article, I assume you know your way around CocoaPods and know how to initialize the needed pods. If not, please check the first step in my previous article.</p>

                                        <h2>Creating the Network Constants class</h2>
                                        <p>This class is the simplest of them all. It’s just a holder for any constant we’ll reuse in our networking layer so that we don’t have any hard-coded values. It goes something like this:</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`struct NetworkConstants {
    
    /// Base URL
    static let baseURL = "https:our-api-base-url.com"
    
    /// Parameter Keys
    enum ParameterKey: String {
        // Auth
        case email = "email"
        case password = "password"
    }
    
    /// The keys for HTTP header fields
    enum HTTPHeaderFieldKey: String {
        case authorization = "Authorization"
        case contentType = "Content-Type"
        case acceptType = "Accept"
        case acceptEncoding = "Accept-Encoding"
    }
    
    /// The values for HTTP header fields
    enum HTTPHeaderFieldValue: String {
        case json = "application/json"
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Really simple, huh?</p>

                                        <h2>Building the API Router</h2>
                                        <p>This component can actually be one of the most essential in this article. This is where all of our endpoints are created into url requests to be later called by our extension.</p>
                                        <p>Although this is not the only approach, I personally like to create my router as an Enum. It takes the HTTPMethod, path, parameters, body and HTTPHeaders; and it gives as a URLRequest in return. Awesome right?</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`enum APIRouter: URLRequestConvertible {
    
    // MARK: - Endpoints
    case getPosts
    case getPostDetails(id: Int)
    case signIn(email: String, password: String)
  
    // MARK: - Properties
    private var method: HTTPMethod {
        switch self {
        case .getPosts,
             .getPostDetails:
            return .get
        case .signIn:
            return .post
        }
    }
  
    private var path: String {
        switch self {
        case .getPosts:
            return "api/posts"
        case .getPostDetails(let id):
            return "api/posts/\(id)"
        case .signIn:
            return "api/auth/login"
        }
    }
  
    private var parameters: Parameters? {
        switch self {
        default:
            return nil
        }
    }
    
    private var body: Parameters? {
        switch self {
        case .signIn(let email, let password):
            return [NetworkConstants.ParameterKey.email.rawValue: email,
                    NetworkConstants.ParameterKey.password.rawValue: password]
        default:
            return nil
        }
    }
    
    // MARK: - Methods
    func asURLRequest() throws -> URLRequest {
        // Construct url
        let url = try NetworkConstants.baseURL.asURL()
        
        // Append path
        var urlRequest = URLRequest(url: url.appendingPathComponent(path))
        
        // Determine HTTP method
        urlRequest.httpMethod = method.rawValue
        
        // Set common headers
        urlRequest.setValue(NetworkConstants.HTTPHeaderFieldValue.json.rawValue,
                            forHTTPHeaderField: NetworkConstants.HTTPHeaderFieldKey.acceptType.rawValue)
        urlRequest.setValue(NetworkConstants.HTTPHeaderFieldValue.json.rawValue,
                            forHTTPHeaderField: NetworkConstants.HTTPHeaderFieldKey.contentType.rawValue)
        
        // Add http body to request
        if let body = body {
            do {
                let data = try JSONSerialization.data(withJSONObject: body, options: .prettyPrinted)
                urlRequest.httpBody = data
            } catch (_) {
                print("APIRouter: Failed to parse body into request.")
            }
        }
        
        // Add query parameters to request
        if let parameters = parameters {
            urlRequest = try URLEncoding.queryString.encode(urlRequest, with: parameters)
        }
        
        return urlRequest
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Looks overwhelming at first? Don’t worry it’s really simple at core. It’s separated into 3 sections; the endpoints defined as enum cases, the properties each being set by a switch/case, and finally a method to pull all of this together and make a URLRequest out of it.</p>
                                        <p>Go through it one more time and you’ll find it’s straightforward.</p>

                                        <h2>Building the Alamofire+PromiseKit extension</h2>
                                        <p>Okay so this part is where I get creative the most. I know this is not the most conventional approach ever, but it has been so good for me. I hope you can see that too.</p>
                                        <p>I start by extending Alamofire's Session, which is where all requests are called starting from Alamofire 5. And I feed an APIRouter endpoint to it. Then it handles all the work and returns a Promise in return.</p>
                                        <p>That’s how this works:</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`extension Session {
    /// Triggers an HTTPRequest using alamofire with a promise as a return type
    func request<T: Codable>(_ urlConvertible: APIRouter) -> Promise<T> {
        return Promise<T> { seal in
            // Trigger the HTTPRequest using Alamofire
            request(urlConvertible).responseDecodable { (response: DataResponse<T, AFError>) in
                // Check result from response and map it the the promise
                switch response.result {
                case .success(let value):
                    seal.fulfill(value)
                case .failure:
                    // If it's a failure, check status code and map it to my error
                    switch response.response?.statusCode {
                    case 400:
                        seal.reject(MyError.badAPIRequest)
                    case 401:
                        seal.reject(MyError.unauthorized)
                    default:
                        guard NetworkReachabilityManager()?.isReachable ?? false else {
                            seal.reject(MyError.noInternet)
                            return
                        }
                        seal.reject(MyError.unknown)
                    }
                }
            }
        }
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>You’ll notice in the failure case, I map the different status codes into an enum called MyError. I will normally name that according to the name of the app, something like OurStoryKitError (We can discuss that Kit bit in a future article)</p>
                                        <p>That error enum would look something like this:</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`public enum MyError: Error {
    
    // MARK: - Internal errors
    case noInternet
    
    // MARK: - API errors
    case badAPIRequest
    
    // MARK: - Auth errors
    case unauthorized
    
    // MARK: - Unknown errors
    case unknown
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Please note that this enum has to conform to Error, or this won’t work properly.</p>

                                        <h2>Making models conform to Codable</h2>
                                        <p>This part is really trivial, but I thought I aught to mention it just in case.</p>
                                        <p>Your models should look something like this:</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`public struct Post {
    
    // MARK: - Properties
    public let id: Int
    public let author: String
    public let content: String
}

// MARK: - Codable
extension Post: Codable {
    enum CodingKeys: String, CodingKey {
        case id = "id"
        case author = "author"
        case content = "content"
    }
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Of course you don’t have to implement that CodingKeys enum, it's just useful when the keys on the returned JSON are different than the properties in your app. (Most APIs use snake_case, while swift's naming convention is CamelCase.</p>

                                        <h2>Making a request</h2>
                                        <p>Congratulations 🎉, you can give yourself a pat on the back. Everything now is working just fine. You just have to actually call the request.</p>
                                        <p>And it’s as simple as that:</p>
                                        <CopyBlock
                                            language="swift"
                                            text=
{`Session().request(.getPosts).done { posts in
    print("TEST - Posts: \(posts)")
}
.catch { error in 
    print("TEST - Error: \(error)")
}
.finally {
    print("TEST - Request is complete 🎉")
}`}
                                            codeBlock
                                            theme={dracula}
                                            showLineNumbers={true}
                                        />
                                        <br />
                                        <p>Of course you won’t always create a new instance of Session everytime you want to use it, this was just for the sake of brevity. This is also where you can inject your RequestInterceptor, but that's a topic for another article.</p>
                                        <p>Now everything is clean and separated and calling your endpoint is as easy as one line. In future articles, we’ll discuss how we can handle token injection into each request, auto retry and so many advanced topics. </p>
                                        <p>I hope you found this article useful.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Details */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default NetworkLayerAlamofirePromisekitBlogDetails;
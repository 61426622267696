import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class GoLimoProjectDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='GoLimo — Mario Mouris' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image golimo_project_bg_image"  data-black-overlay="9">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">GoLimo</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Portfolio Details */}
                <div className="rn-portfolio-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="portfolio-details">
                                    <div className="inner">
                                        <p className="subtitle">GoLimo is a fully customizable & completely automatic labelled app for empowering & improving Taxi business & service in Egypt.</p>
                                        <p>Built with the powerful infrastructure it supports any number of users & drivers. Look nowhere, and step into a turn-key solution.</p>
                                        
                                        <br />

                                        <div className="portfolio-view-list d-flex flex-wrap">
                                            <div className="port-view">
                                                <span>Type</span>
                                                <h4>Freelance Project (Design only)</h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="view-more-btn mt--30 text-center">
                                        <a className="rn-button-style--2 btn-solid mr--10 ml--10" href="https://play.google.com/store/apps/details?gl=US&hl=en&id=com.golimo.passenger"><span>Play Store</span></a>
                                        <a className="rn-button-style--2 btn-solid mr--10 ml--10 mt--10" href="https://golimo-app.com/"><span>Website</span></a>
                                    </div>

                                    <div className="portfolio-thumb-inner mt--40">
                                        <div className="portfolio-images-grid">
                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-1.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-2.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-3.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-4.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-5.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-6.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-7.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-8.png" alt="Portfolio Images"/>
                                            </div>

                                            <div className="portfolio-image">
                                                <img src="/assets/images/portfolio/project-golimo/screenshot-9.png" alt="Portfolio Images"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Details */}

                {/* Start Related Work */}
                <div className="portfolio-related-work pb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center">
                                    <span className="theme-color font--18 fontWeight600">Related Work</span>
                                    <h2>More Projects</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--10">
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/my-work/ava-finance">
                                            <img src="/assets/images/portfolio/project-ava/poster.png" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/my-work/ava-finance">AVA Finance</a></h4>
                                        <span className="category">iOS App</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                            {/* Start Single Portfolio */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="related-work text-center mt--30">
                                    <div className="thumb">
                                        <a href="/my-work/drops">
                                            <img src="/assets/images/portfolio/project-drops/poster.png" alt="Portfolio-images"/>
                                        </a>
                                    </div>
                                    <div className="inner">
                                        <h4><a href="/my-work/drops">Drops</a></h4>
                                        <span className="category">iOS App</span>
                                    </div>
                                </div>
                            </div>
                             {/* End Single Portfolio */}
                        </div>
                    </div>
                </div>
                {/* End Related Work */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />  


            </React.Fragment>
        )
    }
}
export default GoLimoProjectDetails;

const BlogContent = [
    {
        images: '/assets/images/blog/navigation-coordinator-swift/poster.jpg',
        title: 'Navigation and Coordinator Pattern in Swift',
        category: 'Tutorial',
        page: '/blog/navigation-coordinator-pattern-swift'
    },
    {
        images: '/assets/images/blog/network-layer-alamofire-promisekit/poster.jpg',
        title: 'Write a network layer using Alamofire and PromiseKit',
        category: 'Tutorial',
        page: '/blog/network-layer-alamofire-and-promisekit'
    },

    {
        images: '/assets/images/blog/dependency-injection-swift/poster.jpg',
        title: 'Advanced Dependency Injection in Swift',
        category: 'Tutorial',
        page: '/blog/advanced-dependency-injection-in-swift'
    }
]

export default BlogContent;